<script>
import Aws from '@/services/Aws';
import { required} from "vuelidate/lib/validators";
import validationMessages from '@/components/validations'
import Swal from "sweetalert2";


export default {
  components:{
    validationMessages
  },
  props: ['securityGroupData'],
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      showLoader:false,
      modalTitle:'',
      cidrIp: '',
      description:'',
      updatingData: false
    };
  },
  watch: {
    securityGroupData(newData) {
      if (newData) {
        this.cidrIp = newData.cidrIp || '';
        this.description = newData.description || '';
      }
    }
  },
  validations: {
    cidrIp: {
      required,
      valid: function(value) {
        return this.isCidrFormat(value)
      },
    },
  },
  methods: {

    async addIngressRule(){

      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        try {
          await Aws.addSecurityGroupRule({
            GroupId: this.securityGroupData.GroupId,
            CidrIp: this.cidrIp,
            Description: this.description,
            csrf_token: this.csrf_token
          }).then((response) => {
            this.successmsg("New security group rule has been created");
            this.closeModal()
          }).catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error);
          }).finally(() => {
            this.refreshData();
            setTimeout(() => {
              this.tryingToEdit = false
              this.submitted = false
            }, 3000);
          });
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        }
      }

      this.tryingToEdit = false;
    },

    async deleteIngressRule(cidrIp, description){
      Swal.fire({
        title: "Delete Rule " + cidrIp + " for Group: " + this.securityGroupData.GroupName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",

      }).then((result) => {
        if (result.value) {
          this.tryingToEdit = true;
          Aws.removeSecurityGroupRule({
            "GroupId": this.securityGroupData.GroupId,
            "CidrIp": cidrIp
          }).then((response) => {

            const res = response.data.data ? response.data.data : false;
            const error = response.data.error ? response.data.error : '';
            if (res && error == '') {
              this.refreshData()
              this.successmsg("Security group rule successfully deleted")
              this.closeModal()
            } else {
              this.failedmsg("Fail to delete security group rule!")
            }
          }).catch((error) => {
              let errorMsg = error.response.data.error
                      ? error.response.data.error
                      : "";
              this.failedmsg("Fail!", errorMsg);
          }).finally(() => {
            setTimeout(() => {
              this.tryingToEdit = false
              this.submitted = false
            }, 3000);
          });
        }
      });
    },

    refreshData() {
      this.$emit('onRefresh', this.securityGroupData.GroupId, 'updateData')
    },

    closeModal() {
      this.showModal = false;
    },

    initialData(){

    }

  }
};
</script>

<template>
  <b-modal @shown="initialData" v-model="showModal" id="changeSecurityGroupRules_edit" title="Change Security Group Ingress Rules" title-class="font-18" size="xl" centered>
    <form @submit.prevent="addIngressRule">
      <div class="row">
        <div class="col-sm-12">
          <h5>Add New Security Group Rule</h5>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label for="CidrIp">Public IP (CIDR format) :</label>
            <input type="text" id="CidrIp"
                   placeholder="xxx.xxx.xxx.xxx/32"
                   class="form-control"
                   v-model="cidrIp"
                   :class="{
                      'is-invalid': submitted && $v.cidrIp.$error,
                    }"
            />
            <validationMessages v-if="submitted" :fieldName="'CIDR IP'" :validationName="$v.cidrIp"></validationMessages>
          </div>
        </div>
        <div class="col-sm-6 col-md-6">
          <div class="form-group">
            <label for="description">Description :</label>
            <input type="text" id="description"
                   class="form-control"
                   v-model="description"
            />
          </div>
        </div>
        <div class="col-sm-6 col-md-2 align-content-end">
          <b-button variant="primary" @click="addIngressRule" :disabled="tryingToEdit" >
            <b-spinner v-show="tryingToEdit" small></b-spinner>
            Add New Rule</b-button>
        </div>
      </div>
    </form>
    <hr class="mt-3">
    <div class="row mt-5">
      <div class="col-sm-12">
        <h5>{{securityGroupData.GroupName}} - Security Group Rules</h5>
      </div>
      <div class="col-md-12" v-if="!tryingToEdit">

        <table class="table table-responsive">
          <thead>
          <tr>
            <th>IP Protocol</th>
            <th>From Port</th>
            <th>To Port</th>
            <th>Ip Ranges (CIDR)</th>
          </tr>
          </thead>
          <tbody v-if="securityGroupData.IngressRules && securityGroupData.IngressRules.length">
            <tr v-for="item in securityGroupData.IngressRules" :key="item.IpProtocol+'-'+item.FromPort">
              <td>{{item.IpProtocol}} </td>
              <td>{{item.FromPort}}</td>
              <td>{{item.ToPort}}</td>
              <td>
                <div class="row" v-for="IpRanges in item.IpRanges" :key="IpRanges.CidrIp">
                  <div class="col-10">
                    {{IpRanges.CidrIp}} <span v-if="IpRanges.Description">({{IpRanges.Description}})</span>
                  </div>
                  <div class="col-2">
                    <a href="javascript:void(0);" @click="deleteIngressRule(IpRanges.CidrIp, IpRanges.Description)">
                      <i class="uil uil-trash-alt font-size-18 text-danger" ></i>
                    </a>
                  </div>
                </div>
              </td>

            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">No Data Found</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body d-flex align-items-center justify-content-center" v-else>
        <b-spinner large></b-spinner>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
    </template>
  </b-modal>
</template>